<template>
  <BaseListPage locale-section="pages.languages" route="language">
    <LanguagesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "LanguagesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    LanguagesList: () => import("./LanguagesList")
  }
};
</script>
